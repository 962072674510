.Header {
  background-color: #6200EE;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Header-combined {
  display: flex;
  flex-direction: column;
}

.Top-header {
  background-color: black;
  height: 0.5em;
}

.Footer {
  background-color: #6200EE;
  display: flex;
  flex-direction: column;
}

.Footer-copyright {
  background-color: black;
  height: 1.5em;
  text-align: center;
}

.Copyright-text {
  color: white;
  font-size: 80%;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.Title-container {
  margin-left: 5%;
  margin-top: 1%;
  height: 10em;
  justify-self: center;
}

.Title {
  color: white;
  font-size: 250%;
  font-family: 'Marmelad', sans-serif;
  font-weight: bold;
}

.Sub-title {
  color: white;
  font-size: 120%;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
}

.Navbar {
  margin-right: 10%;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.Nav-link {
  font-family: 'Open Sans', sans-serif;
  font-size: 100%;
  font-weight: bold;
  background-color: #6200EE;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  border-radius: 5px;
  margin-left: 10%;
  color: white;
  padding: 8px 20px;
  cursor: pointer;
  text-decoration: none;
}

.Nav-link:hover {
  font-family: 'Open Sans', sans-serif;
  font-size: 100%;
  font-weight: bold;
  background-color: white;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  border-radius: 5px;
  margin-left: 10%;
  color: black;
  padding: 8px 20px;
  cursor: pointer;
  text-decoration: none;
}

.Image {
  width: 30%;
  margin-left: 5%;
  margin-right: 5%;
}

.Image1-of-2 {
  width: 48%;
  margin-bottom: 10%;
}

.Image2-of-2 {
  width: 48%;
  margin-left: 3%;
}

.Texts {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
}

.Subscribe-text {
  color: black;
  font-size: 220%;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  width: 50%;
  margin-top: 5%;
  text-align: center;
}

.Primary-text {
  color: black;
  font-size: 300%;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
}

.Secondary-text {
  color: black;
  font-size: 140%;
  font-family: 'Open Sans', sans-serif;
  margin-top: 8%;
}

.Page {
  display: flex;
  flex-direction: column;
}

.Text-and-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.Subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F9F9F9;
}

.Input-and-button {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 50%;
  align-items: center;
  align-content: center;
}

.Text-input-background {
  background-color: #ECF0F9;
  border-radius: 35px;
  margin-right: 5%;
  height: 3em;
  width: 70%;
  padding-left: 5%;
}

.Text-input {
  background-color: #ECF0F9;
  border-color: #ECF0F9;
  border-style: none;
  border-radius: 35px;
  margin-top: 3%;
  width: 90%;
  height: 2em;
}

input:focus {
  outline: none;
}

input[type=submit]:hover {
  cursor: pointer;
}

.Sign-up-button {
  background-color: #FEBE3A;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  height: 3em;
  width: 30%;
  border-radius: 5px;
  margin-left: 5%;
}

.Form-and-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.Email-success-message {
  color: #6200EE;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bold;
  margin-bottom: 5%;
  text-align: center;
}

.hideMeAfter5Seconds {
  animation: hideAnimation 0s ease-in 5s;
  animation-fill-mode: forwards;
}

@keyframes hideAnimation {
  to {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

/* Phone view */
@media (width <= 500px) {
  .Text-and-image {
    flex-direction: column;
  }
  .Reverse-column-mobile {
    flex-direction: column-reverse;
  }
  .Image {
    margin-bottom: 5%;
    width: 70%;
  }
  .Images {
    margin-bottom: 5%;
    margin-left: 10%;
  }
  .Image1-of-2 {
    width: 40%;
    margin-bottom: 10%;
  }
  .Image2-of-2 {
    width: 40%;
    margin-left: 3%;
  }
  .Navbar {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
  }
  .Nav-link {
    margin-bottom: 10%;
    margin-left: 10%;
  }
  .Subscribe-text {
    width: 80%;
  }
  .Input-and-button {
    width: 80%;
  }
  .Text-input-background {
    width: 65%;
  }
  .Text-input {
    margin-top: 5%;
  }
  .Sign-up-button {
    width: 35%;
  }
}